import * as Styled from './Reviews.styles';
import googleLogo from '../../assets/images/googleLogo.png';
import fiveStars from '../../assets/images/fiveStars.png';
const Review = ({ review }) => (
    <Styled.ReviewCard>
      <Styled.ReviewText>{review.text}</Styled.ReviewText>
      <Styled.ReviewDetails>
        <Styled.AuthorLocationContainer>
          <Styled.ReviewAuthor>{review.author}</Styled.ReviewAuthor>
          <Styled.ReviewLocation>{review.location}</Styled.ReviewLocation>
        </Styled.AuthorLocationContainer>
        <div>
          <Styled.IconLink href="https://maps.app.goo.gl/mHBRMySH1KKgjjnz5" target="Google-Profile">
            <img src={googleLogo} alt="Google Logo" width="20" />
          </Styled.IconLink>
          <Styled.IconLink href="https://maps.app.goo.gl/mHBRMySH1KKgjjnz5" target="Google-Profile">
            <img src={fiveStars} alt="Five Star" width="90" />
          </Styled.IconLink>
        </div>
      </Styled.ReviewDetails>
    </Styled.ReviewCard>
  );

  export default Review;