import styled from 'styled-components';

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ReviewCard = styled.div`
    background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 60%;

  &:hover {
    transform: translateY(-5px); // Lifts the container up by 5 pixels
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); // Adds a soft shadow beneath
  }

  @media (max-width: 1000px) { // Tablet breakpoint
    width: 80%;
  }

  @media (max-width: 600px) { // Mobile breakpoint
    /* width: 95%; */
  }
`;

export const ReviewTitle = styled.h1`
    margin: 0.5rem 0rem 1rem 0rem;
    display: inline-block;
    width: 35%;
    font-size: 2rem;
    color: #ffffff;         // White text
    text-align: center;
    word-spacing: 3px;
    letter-spacing: 1px;    // Slight letter spacing for modern typography
    font-weight: 500;       // Medium-bold weight
    background-color: #333; // Dark background
    border-radius: 10px;    // Rounded corners
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),          // Subtle box shadow for depth
        0 2px 4px rgba(0, 0, 0, 0.06); 
    text-shadow: 
        1px 1px 2px rgba(0, 0, 0, 0.2);        // Subtle text shadow for better text contrast
    transition: transform 0.2s ease, box-shadow 0.2s ease; // Smooth transition for hover effect

    &:hover {
        transform: translateY(-2px);            // Slight upward movement on hover
        box-shadow: 
            0 6px 8px rgba(0, 0, 0, 0.12), 
            0 4px 6px rgba(0, 0, 0, 0.08);
    }

    // Tablets
    @media (max-width: 1000px) {
        width: 50%;
        font-size: 2rem;
    }

    // Mobiles
    @media (max-width: 600px) {
        width: 80%;
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }
`;

export const ReviewText = styled.p`
  flex: 1;
  padding: 0 2rem;
  padding-right: 5rem;
  word-spacing: 3px;
  font-size: 1rem;
  line-height: 1.5;

  @media (max-width: 1000px) { // Tablet breakpoint
    padding: 0 1.5rem;
    padding-right: 3rem;
  }

  @media (max-width: 600px) { // Mobile breakpoint
    padding: 0 1rem;
    padding-right: 2rem;
  }
`;

export const ReviewDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) { // Tablet breakpoint
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const ReviewAuthor = styled.span`
  @media (max-width: 1000px) { // Tablet breakpoint
    font-weight: bold;
  }`;

export const ReviewLocation = styled.span`
  font-weight: bold;
`;

export const IconLink = styled.a`
  margin-left: 0.5rem;
`;

export const AuthorLocationContainer = styled.div`
  display: flex;
  flex-direction: column;
`