import React from 'react';
import * as Styled from './Services.styles';
import exteriorFinishImg from '../../assets/images/exterior-finish.png';
import interiorFinishImg from '../../assets/images/interior-finish.png';
import customMillworkImg from '../../assets/images/custom-millwork.png';

class ServicesComponent extends React.Component {
  state = {
    flippedCard: null,
  };

  handleFlip = (index) => {
    this.setState({ flippedCard: this.state.flippedCard === index ? null : index });
  };

  render() {
    const services = [
      {
        image: exteriorFinishImg,
        title: "EXTERIOR FINISH",
        description: "ELEVATING EXTERIOR AESTHETICS: OUR EXPERTISE IN FINISH CARPENTRY TRANSFORMS THE FACADE OF YOUR SPACE. INCLUDES, DECKS, PERGOLA'S, GAZEBOS, POOL CABANA'S AND FENCES.",
    },
    {
        image: customMillworkImg,
        title: "CUSTOM MILLWORK",
        description: "CRAFTING UNIQUE VISIONS IN WOOD: OUR CUSTOM MILLWORK ELEVATES YOUR SPACE WITH UNMATCHED PRECISION AND ELEGANCE. INCLUDES, CUSTOM BUILT IN UNITS. CUSTOM CLOSETS, ENTERTAINMENT UNITS AND ALTERNATIVE MILLWORK DESIGNS.",
    },
    {
        image: interiorFinishImg,
        title: "INTERIOR FINISH",
        description: "MASTERING THE ART OF INTERIOR FINISH CARPENTRY: CRAFTING ELEGANCE, ONE DETAIL AT A TIME. INCLUDES INTERIOR DOORS, CUSTOM TRIM WORK, CUSTOM CEILING DESIGNS, WAINSCOTING AND WALL PANELLING."
    },
    ];

    console.log('x: ', this.state.flippedCard);

    return (
      <>
      <Styled.ServicesTitle id="services">SERVICES</Styled.ServicesTitle>
      <Styled.ServicesContainer>
        {services.map((service, index) => (
          <div key={index}>
            <Styled.ServiceCard onClick={() => this.handleFlip(index)}>
              <Styled.InnerCard isflipped={(this.state.flippedCard === index)}>
                <Styled.CardFront imgurl={service.image}></Styled.CardFront>
                <Styled.CardBack>{service.description}</Styled.CardBack>
              </Styled.InnerCard>
            </Styled.ServiceCard>
            <Styled.ServiceTitle>{service.title}</Styled.ServiceTitle>
          </div>
        ))}
      </Styled.ServicesContainer>
      </>
    );
  }
}

export default ServicesComponent;