// import styled from 'styled-components';

// // NavBar Styled Components
// export const NavBarContainer = styled.div`
//     align-items: center;
//     justify-content: center;
//     padding: 1rem 2rem;
//     background-color: black;
// `;

// export const NavItems = styled.ul`
//     list-style: none;
//     display: flex;
//     gap: 1.5rem;

//     li {
//         color: white;
//         cursor: pointer;
//         transition: color 0.2s, transform 0.2s;
//         font-size: 13px;
//         &:hover {
//             color: grey;
//             transform: scale(1.05);
//         }
//     }

//     a {
//         color: white;
//         text-decoration: none;
//     }
// `;

import styled from 'styled-components';

const tablet = `@media (max-width: 1000px)`;
const mobile = `@media (max-width: 600px)`;

// NavBar Styled Components
export const NavBarContainer = styled.div`
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    background-color: black;

    ${tablet} {
        padding: 0.8rem 1.5rem;
    }

    ${mobile} {
        padding: 0.5rem 1rem;
    }
`;

export const NavItems = styled.div`
    list-style: none;
    display: flex;
    gap: 1.5rem;

    li {
        color: white;
        cursor: pointer;
        transition: color 0.2s, transform 0.2s;
        font-size: 13px;

        &:hover {
            color: grey;
            transform: scale(1.05);
        }
    }

    a {
        color: white;
        text-decoration: none;
        padding-left: 0;
    }

    ${tablet} {
        gap: 1rem;
        li {
            font-size: 12px;
        }
    }

    ${mobile} {
        flex-direction: row;  // Stack items vertically on mobile for better touch accessibility
        align-items: center;
        gap: 0.7rem;

        li {
            font-size: 11px;
        }
    }
`;
