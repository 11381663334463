import styled from 'styled-components';

export const AboutContainer = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 7%;
    background-color: #f4f4f4;
    word-spacing: 3px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    h2 {
        word-spacing: 3px;
        font-size: 4rem;
    }
    
    @media (max-width: 1000px) {
        flex-direction: column;
        text-align: center;
        padding: 2rem 5%;
    }
`;

export const AboutTitle = styled.h1`
     margin: 0rem 0rem 1rem 1rem;
    display: inline-block;
    font-size: 2.5rem;
    color: #ffffff;         // White text
    text-align: center;
    width: 75%;
    word-spacing: 3px;
    letter-spacing: 1px;    // Slight letter spacing for modern typography
    font-weight: 600;       // Medium-bold weight
    background-color: #333; // Dark background
    border-radius: 10px;    // Rounded corners
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),          // Subtle box shadow for depth
        0 2px 4px rgba(0, 0, 0, 0.06); 
    text-shadow: 
        1px 1px 2px rgba(0, 0, 0, 0.2);        // Subtle text shadow for better text contrast
    transition: transform 0.2s ease, box-shadow 0.2s ease; // Smooth transition for hover effect

    &:hover {
        transform: translateY(-2px);            // Slight upward movement on hover
        box-shadow: 
            0 6px 8px rgba(0, 0, 0, 0.12), 
            0 4px 6px rgba(0, 0, 0, 0.08);
    }

    // Tablets
    @media (max-width: 1000px) {
        width: 90%;
        font-size: 2rem;
    }

    // Mobiles
    @media (max-width: 600px) {
        width: 80%;
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }
`

export const AboutText = styled.div`
    flex: 1;
    padding-right: 5rem;
    word-spacing: 3px;

    width: 100%;
    text-align: center;
    h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.5;
    }


    @media (max-width: 1000px) {
        padding-right: 0;
    }
`;



export const CTAContainer = styled.div`
    flex: 1;
    margin-bottom: 2rem;
    padding-right: 5rem;
    word-spacing: 3px;
    width: 100%;
    text-align: center;

    p {
        font-size: 1rem;
        line-height: 1.5;
    }

    @media (max-width: 1000px) {
        padding-right: 0;
        margin-bottom: 1rem;
    }

`;

export const CTA = styled.p`
        
`;

export const AboutImage = styled.img`
   height: 10%;
    width: 10%;
    flex: 1;
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    /* Alternatively, for a gradient border */
    border: 3px solid transparent;
    background-image: #DFD4D2;
    /* background-clip; content-box: border-box; */

    @media (max-width: 1000px) {
        width: 80%;
        margin-top: 0;
        height: auto;  // Adjust height proportionally
    }
`;
