import React, { useState } from 'react';
import * as Styled from './Gallery.styles';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const images = [
  { alt: 'Description 1', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g1.png' },
  { alt: 'Description 2', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g2.png' },
  { alt: 'Description 3', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g3.png' },
  { alt: 'Description 4', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g4.png' },
  { alt: 'Description 5', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g5.png' },
  { alt: 'Description 7', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g7.png' },
  { alt: 'Description 8', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g8.png' },
  { alt: 'Description 9', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g9.png' },
  { alt: 'Description 10', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g10.png' },
  { alt: 'Description 11', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g11.png' },
  { alt: 'Description 12', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g12.png' },
  { alt: 'Description 13', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g13.png' },
  { alt: 'Description 14', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g14.png' },
  { alt: 'Description 15', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g15.png' },
  { alt: 'Description 16', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g16.png' },
  { alt: 'Description 17', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g17.png' },
  { alt: 'Description 18', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g18.png' },
  { alt: 'Description 19', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g19.png' },
  { alt: 'Description 20', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g20.png' },
  { alt: 'Description 21', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g21.png' },
  { alt: 'Description 22', url: 'http://arjunrasodha.com/PlatinumCarpentryMillwork/gallery_new/g22.png' },
];

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  // You can set a breakpoint for when to switch to mobile styles.
const isMobile = window.innerWidth <= 768; // 1000px for tablet breakpoint.

const containerStyles = {
    width: '100%',
    height: isMobile ? '30rem' : '50rem',
    margin: "1rem auto",
    textAlign: 'center',
    alignItems: 'center',
    fontSize: isMobile ? '1rem' : '1.2rem',
    wordSpacing: '3px',
    marginTop: "0",
    backgroundColor: '#f9f9f9',
};

const slideStyles = {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${images[currentIndex].url})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
};

const sliderStyles = {
    height: '100%',
    position: 'relative',
};

const arrowStyles = {
    position: 'absolute',
    top: '50%',
    transform : 'translate(0, -50%)',
    fontSize: isMobile ? '30px' : '45px',  // Reduce font size for mobile
    color: '#fff',
    zIndex: 1,
    cursor: 'pointer',
};

const leftArrowStyles = {
    ...arrowStyles,
    left: isMobile ? '16px' : '32px',
};

const rightArrowStyles = {
    ...arrowStyles,
    right: isMobile ? '16px' : '32px',
};

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1: currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length  - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  // const dotsContainerStyles = {
  //   display: 'flex',
  //   justifyContent: 'center'

  // }

  // const dotStyles = {
  //   color: 'white',
  //   display: isMobile ? 'none' : 'visible' // set size to 2px on mobile
  // }

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  }

  return (
    <>
   <div style={containerStyles} id="gallery">
   <Styled.GalleryTitle>GALLERY</Styled.GalleryTitle>
      <div style={sliderStyles}>
      <div style={leftArrowStyles} onClick={goToPrevious}>&#10096;</div>
      <div style={rightArrowStyles} onClick={goToNext}>&#10097;</div>
        <div style={slideStyles}>
        {/* <div style={dotsContainerStyles}> */}
          {images.map((slide, slideIndex) => (
            <div key={slideIndex} onClick={() => goToSlide(slideIndex)}></div>
          ))}
          </div>
        {/* </div> */}
      </div>
   </div>
   </>
  );
};

export default Gallery;
