import * as Styled from './Contact.styles';

const ContactUs = () => {
    return (
        <Styled.ContactSection>
            <Styled.ContactButton><a href="#contact">CONTACT US</a></Styled.ContactButton>
            <Styled.ContactDescription>
            HAVE QUESTIONS? WE'RE HERE TO HELP. GET IN TOUCH WITH US TODAY!
            </Styled.ContactDescription>
        </Styled.ContactSection>
    );
}

export default ContactUs;