import * as Styled from './Footer.styles';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import GoogleIcon from '@mui/icons-material/Google';
import pcmLogo from '../../assets/images/pcm-logo.png';
import NavBar from '../NavBar/NavBar';

const Footer = () => {
    const pcmEmail = 'info@platinumcarpentrymillwork.com';
    const pcmEmailMailto = `mailto:${pcmEmail}`;

    return (
        <>
        <Styled.FooterContainer>
            <Styled.Logo src={pcmLogo} alt="Company Logo" />
            <NavBar />
            <Styled.FooterItem href="#">PLATINUM CARPENTRY & MILLWORK</Styled.FooterItem>
            <Styled.ContactContainer>
                <Styled.FooterItem href={pcmEmailMailto}><EmailIcon />info@platinumcarpentrymillwork.com</Styled.FooterItem>
                <Styled.FooterItem href="https://www.instagram.com/platinumcarpentrymillwork/" target="_blank" rel="noopener noreferrer">
                    <InstagramIcon /> @platinumcarpentrymillwork
                </Styled.FooterItem>
                <Styled.FooterItem href="https://maps.app.goo.gl/PJJ3oPCAWkVFmxj39">
                    <GoogleIcon /> Platinum Carpentry & Millwork
                </Styled.FooterItem>
            </Styled.ContactContainer>
            <Styled.FooterItem href="tel:+16476318623"><PhoneIcon /> ‭+1 (647) 631-8623‬</Styled.FooterItem>
        </Styled.FooterContainer>
        </>
    );
};

export default Footer;