import styled from 'styled-components';

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  height: 28rem;
  margin: 0 3rem;
  
  @media (max-width: 1000px) { 
    flex-direction: column; 
    height: 75rem;
    padding: 0;
    margin: auto;
    align-items: center;
  }


  @media (max-width: 600px) { // Mobile breakpoint
    flex-direction: column; 
    height: 75rem;
    padding: 0;
    margin: auto;
    align-items: center;
  }
`;

export const ServicesTitle = styled.h1`
    margin: 2rem 0rem 1rem 0rem;
    display: inline-block;
    width: 30%;
    font-size: 2rem;
    color: #ffffff;         // White text
    text-align: center;
    word-spacing: 3px;
    letter-spacing: 1px;    // Slight letter spacing for modern typography
    font-weight: 500;       // Medium-bold weight
    background-color: #333; // Dark background
    border-radius: 10px;    // Rounded corners
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),          // Subtle box shadow for depth
        0 2px 4px rgba(0, 0, 0, 0.06); 
    text-shadow: 
        1px 1px 2px rgba(0, 0, 0, 0.2);        // Subtle text shadow for better text contrast
    transition: transform 0.2s ease, box-shadow 0.2s ease; // Smooth transition for hover effect

    &:hover {
        transform: translateY(-2px);            // Slight upward movement on hover
        box-shadow: 
            0 6px 8px rgba(0, 0, 0, 0.12), 
            0 4px 6px rgba(0, 0, 0, 0.08);
    }

    // Tablets
    @media (max-width: 1000px) {
        width: 50%;
        font-size: 2rem;
    }

    // Mobiles
    @media (max-width: 600px) {
        width: 80%;
        font-size: 1.5rem;
        margin: 1.5rem 0;
    }
`;

export const ServiceCard = styled.div`
  /* width: 30%; */
  height: 19rem;  /* Adjusted to accommodate card height */
  perspective: 1000px;
  margin-bottom: 20px;
  padding: 1rem;
  
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    text-align: center;
    border-color: red;
    margin-right: 2.2rem; //  TODO: REMOVE IF CAUSING PROBLEMS
  }
`;

export const InnerCard = styled.div`
  width: 15rem;
  height: 20rem;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform: rotateY(${props => (props.isflipped ? '180' : '0')}deg);
`;

export const CardFace = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 4px;
  padding: 0 1rem;
  transform: translateX(-1.5rem);

  @media (max-width: 1000px) { // Mobile breakpoint
    border-color: red;
    margin: auto;
    align-items: center;
    /* justify-content: space-around; */
    /* transform: translateX(1.5rem); */
    transform: translateX(0.1rem);
    /* background-color: red; */
    /* justify-content:  */
  }

  @media (max-width: 600px) { // Mobile breakpoint
    border-color: red;
    margin: auto;
    align-items: center;
    /* justify-content: space-around; */
    /* transform: translateX(1.5rem); */
    transform: translateX(0.1rem);
    /* background-color: red; */
    /* justify-content:  */
  }
  
`;

export const CardFront = styled(CardFace)`
  background: url(${props => props.imgurl}) no-repeat center/cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
        /* width: 100%; */
        /* transform: translateX(0.2rem); */
    }
`;

export const CardBack = styled(CardFace)`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  word-spacing: 3px;
  transform: rotateY(180deg);

  @media (max-width: 600px) {
        /* width: 100%; */
        /* transform: translateX(0.2rem); */
    }
`;

export const ServiceTitle = styled.div`
transform: translateX(-0.5rem);
padding: 0 16px;
  background-color: #333; // Dark background
  border-radius: 10px;    // Rounded corners
  text-align: center;
  color: white;
  /* margin-top: 13vh; */
  font-size: 20px;
`;
