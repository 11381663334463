import React, { useState } from 'react';
import * as Styled from './ContactForm.styles';

const pcmEmail = 'info@platinumcarpentrymillwork.com';
const blankFormData = {
    name: '',
    email: '',
    phone: '',
    jobType: '',
    description: ''
};

const ContactForm = () => {
    const [formData, setFormData] = useState(blankFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        const { name, email, phone, jobType, description } = formData;

        const emailSubject = encodeURIComponent(`New project inquiry from ${name}`);
        const emailBody = encodeURIComponent(
            `Name: ${name}\n\nEmail: ${email}\n\nPhone: ${phone}\n\nJob Type: ${jobType}\n\nDescription:\n${description}`
        );

        const mailtoLink = `mailto:${pcmEmail}?subject=${emailSubject}&body=${emailBody}`;

        // Open the default mail client
        window.location.href = mailtoLink;
        setFormData(blankFormData);
    };

    return (
        <Styled.FormContainer id="contact">
            <Styled.Title>CONTACT US</Styled.Title>
            <Styled.Input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
            <Styled.Input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
            <Styled.Input type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} />
            <Styled.Select name="jobType" value={formData.jobType} onChange={handleChange}>
                <option value="">Select Job Type</option>
                <option value="Interior Carpentry">Interior Carpentry</option>
                <option value="Exterior Carpentry">Exterior Carpentry</option>
                <option value="Millwork">Millwork</option>
                <option value="Other">Other</option>
            </Styled.Select>
            <Styled.Textarea name="description" placeholder="Describe your project" rows="5" value={formData.description} onChange={handleChange}></Styled.Textarea>
            <Styled.Button href={`mailto:${pcmEmail}`} onClick={handleSubmit}>Send Message</Styled.Button>
        </Styled.FormContainer>
    );
};

export default ContactForm;