import styled from 'styled-components';

export const HRDiv = styled.div`
    width: 100%;
    align-items: center;
    text-align: center;
    /* margin: 6rem 0 0 0; // Remove horizontal margin to make better use of width on smaller screens */

    @media (max-width: 1000px) {
        margin: 2rem 0; // Reduce vertical margin for mobiles and tablets
    }
`;

export const HR = styled.hr`
    width: 80%;           // It will take full width of its parent
    border: 0;             // Removing default border
    height: 1px;           // Define thickness
    background: black;   // Color of the rule
    margin-bottom: 1rem;        // Space above and below

    @media (max-width: 1000px) {
        width: 90%;       // Increase width for better visibility on smaller screens
        height: 0.75px;   // Slightly thinner line for mobiles
    }
`;
