import * as Styled from './HorizontalRule.styles'

const HorizontalRule = () => {
    return (
        <Styled.HRDiv>
            <Styled.HR />
        </Styled.HRDiv>
    )
}

export default HorizontalRule;