// import styled from 'styled-components';

// // Styled Components
// export const QualitiesContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0.5rem;
//     background-color: #f9f9f9;  // Light gray background
//     border-radius: 10px;
//     border-color: black;
//     margin: 1rem;
//     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
// `;

// export const Quality = styled.p`
//     font-family: 'Open Sans', sans-serif;  // Assuming you have imported this font or replace with your desired font
//     font-size: 1.2rem;
//     color: #333;
//     margin-top: 0.5rem;
//     margin-bottom: 0.5rem;
//     font-weight: bold;

//     &:hover {
//         transform: translateY(-5px);  // Shifts the text up by 5 pixels
//         color: grey;
//     }
// `;

import styled from 'styled-components';

const tablet = `@media (max-width: 1000px)`;
const mobile = `@media (max-width: 600px)`;

// Styled Components
export const QualitiesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    background-color: #f9f9f9;  // Light gray background
    border-radius: 10px;
    border-color: black;
    margin: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    ${tablet} {
        flex-direction: row;
        padding: 0.5rem;
    }

    ${mobile} {
        flex-direction: column;
        padding: 0.5rem;
    }
`;

export const Quality = styled.p`
    font-family: 'Open Sans', sans-serif;  // Assuming you have imported this font or replace with your desired font
    font-size: 1.2rem;
    color: #fff;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;

    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

    /* Initial state: subtle shadow and scaled down slightly */
    transform: scale(0.98);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    color: #333;

    &:hover {
        /* Hover state: Rotate slightly, lift (scale up), deepen shadow, and change color */
        transform: scale(1.05) rotate(-3deg); 
        color: #333; 
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2); 
    }


    ${tablet} {
        font-size: 1.1rem;
    }

    ${mobile} {
        font-size: 1rem;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
`;
