import styled from 'styled-components';

const tablet = `@media (max-width: 1000px)`;
const mobile = `@media (max-width: 600px)`;

// HeroImage Styled Components
export const HeroContainer = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
    background-color: black;
    padding: 1rem 0 2rem 0;
    text-align: center;

    ${tablet} {
        width: 100%;
    }

    ${mobile} {
        width: 100%;
    }
`;

export const HeroImg = styled.img`
    max-width: 60%;
    max-height: 60%;
    object-fit: cover;
`;

export const HeroText = styled.div`
    position: absolute;
    bottom: 10%;
    left: 5%;
    color: #fff;
    font-size: 2rem;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 1rem;
    border-radius: 8px;

    ${tablet} {
        width: 100%;
    }
`;