import styled from 'styled-components';

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: white;
`;


export const GalleryTitle = styled.h1`
    margin: 0.5rem 0rem 2rem 0rem;
    display: inline-block;
    width: 30%;
    font-size: 2rem;
    color: #ffffff;         // White text
    text-align: center;
    word-spacing: 3px;
    letter-spacing: 1px;    // Slight letter spacing for modern typography
    font-weight: 500;       // Medium-bold weight
    background-color: #333; // Dark background
    border-radius: 10px;    // Rounded corners
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),          // Subtle box shadow for depth
        0 2px 4px rgba(0, 0, 0, 0.06); 
    text-shadow: 
        1px 1px 2px rgba(0, 0, 0, 0.2);        // Subtle text shadow for better text contrast
    transition: transform 0.2s ease, box-shadow 0.2s ease; // Smooth transition for hover effect

    &:hover {
        transform: translateY(-2px);            // Slight upward movement on hover
        box-shadow: 
            0 6px 8px rgba(0, 0, 0, 0.12), 
            0 4px 6px rgba(0, 0, 0, 0.08);
    }

    // Tablets
    @media (max-width: 1000px) {
        width: 50%;
        font-size: 2rem;
    }

    // Mobiles
    @media (max-width: 600px) {
        width: 80%;
        font-size: 1.5rem;
        margin: 0.5rem 0;
    }
`;


export const ImageContainer = styled.div`
margin-top: 1rem;
  display: flex;
  transition: transform 0.5s;
`;

export const SlideImage = styled.img`
margin-top: 1rem;
  max-width: 100%;
  height: auto;
`;

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 24px;
  z-index: 2;
  &:focus {
    outline: none;
  }
`;