import * as Styled from './About.styles';
import precisionImg from '../../assets/images/precision.jpg'
const AboutSection = () => {
    return (
        <Styled.AboutContainer>
            <Styled.AboutText>
            <Styled.CTAContainer>
            <Styled.CTA>PLATINUM CARPENTRY AND MILLWORK SPECIALIZES IN PREMIUM MODERN CARPENTRY AND MILLWORK SERVICES. WE EXCEL AT CRAFTING STUNNING INTERIORS AND DELIVERING A PERSONALIZED RENOVATION JOURNEY FOR CONTRACTORS, BUILDERS, AND HOMEOWNERS ALIKE. CONTACT US FOR A QUOTE..</Styled.CTA>
            </Styled.CTAContainer>
                <Styled.AboutTitle>OUR MISSION</Styled.AboutTitle>
                <p>AT PLATINUM CARPENTRY AND MILLWORK, OUR MISSION IS TO CRAFT NOT JUST BEAUTIFUL AND FUNCTIONAL WOODWORK BUT ALSO LASTING RELATIONSHIPS WITH OUR CLIENTS. WE ARE DEDICATED TO DELIVERING EXCEPTIONAL CRAFTSMANSHIP IN EVERY PROJECT, FROM CUSTOM HOMES TO HOME RENOVATIONS. TOGETHER, WE BUILD DREAMS INTO REALITY, ONE METICULOUSLY CRAFTED DETAIL AT A TIME.</p>
            </Styled.AboutText>
            <Styled.AboutImage src={precisionImg} alt="About us" />
        </Styled.AboutContainer>
    );
}

export default AboutSection;