import * as Styled from './Reviews.styles';
import Review from './Review';

const reviewData = [
  { text: "We had statement walls and storage done in our home, as well as a ceiling. They are STUNNING. Everyone who comes over comments on how beautiful they are. His work is detailed, and clean. He works in such a clean and organized manner it was amazing. My dad works on the trades and has worked with many carpenters and he was so impressed by the quality of work and craftsmanship.", author: "Jacquelyne Ferreira", location: "Mississauga, ON" },
  { text: "HI highly recommend Platinum Carpentry & Millwork. Deep’s passion and skill level shines through his work. Thankful for his care and attention to detail.", author: "Wendy Boaventura", location: "Toronto, ON" },
  { text: "I’ve used Platinum Carpentry & Millwork for full trim installs on several complete house builds and can always count on a clean and professional job. They know what good work should look like and deliver on time and within budget. Deep is friendly, honest, reliable and a great communicator. He’s my first call whenever I need carpentry work done. Highly recommend.", author: "Richard Poissant", location: "Toronto, ON" },
  { text: "Excellent work ethic and great build quality. Great communication from the team. Easy to work with.", author: "Manny Singh  ", location: "Etobicoke, ON" },
];

const Reviews = () => {
    return (
      <Styled.ReviewContainer>
        <Styled.ReviewTitle>WHAT PEOPLE SAY</Styled.ReviewTitle>
        {reviewData.map((review, index) => (
          <Review key={index} review={review} />
        ))}
      </Styled.ReviewContainer>
    );
  };
  
  export default Reviews;

  