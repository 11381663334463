import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background-color: black;
    padding: 1.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) { // Tablet breakpoint
        padding: 1rem;
    }
`;

export const Logo = styled.img`
    width: 15rem; 
    margin-bottom: 1rem;

    @media (max-width: 1000px) { // Tablet breakpoint
        width: 12rem;
    }

    @media (max-width: 600px) { // Mobile breakpoint
        width: 10rem;
    }
`;
export const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 1000px) { // Tablet breakpoint
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
    }
`
export const FooterItem = styled.a`
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0.5rem 0.5rem;
    &:hover {
        text-decoration: underline;
    }
    svg {
        margin-right: 8px;
    }

    @media (max-width: 1000px) { // Tablet breakpoint
        margin: 0.5rem 0;
    }

`;