import * as Styled from './NavBar.styles'

const NavBar = () => (
    <Styled.NavBarContainer id="home">
        <Styled.NavItems>
            <li><a href="#home">HOME</a></li>
            <li><a href="#about">ABOUT</a></li>
            <li><a href="#services">SERVICES</a></li>
            <li><a href="#gallery">GALLERY</a></li>
            <li><a href="#contact">CONTACT</a></li>
        </Styled.NavItems>
    </Styled.NavBarContainer>
);

export default NavBar;