import './App.css';
import { Header } from './components/Header/Header';
import About from './components/About/About';
import Gallery from './components/Gallery/Gallery';
// import Carousel from './components/Carousel/Carousel';
// import ThemeProviderComponent from './components/ThemeProvider/ThemeProvider'
import Qualities from './components/Qaulities/Qualities';
// import Services from './components/Services/Services';
import HorizontalRule from './components/HorizontalRule/HorizontalRule';
import ContactUs from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import ContactForm from './components/ContactForm/ContactForm';
import Reviews from './components/Reviews/Reviews';
import { createGlobalStyle } from 'styled-components';
import backgroundImage from './assets/images/background.jpg';
import Services from './components/Services2/Services';

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url(${backgroundImage});
    background-size: cover;  // to cover the entire viewport
    background-repeat: no-repeat;  // to prevent the image from repeating
    background-position: center;  // to center the image
  }
`;

const isMobile = window.innerWidth <= 320; // 1000px for tablet breakpoint.

const appStyles = {
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: isMobile ? '350px' : '100%',
  minWidth: '400px'
 } 

function App() {
  return (
    <div className="App" style={appStyles}>
      <GlobalStyle />
      <Header />
      <Qualities />
      <About />
      <Services />
      <HorizontalRule />
      <ContactUs />
      <HorizontalRule />
      <Gallery />
      <ContactForm />
      <Reviews />
      <Footer />
      {/* <Gallery /> */}
      {/* <Carousel /> */}
      {/* <ThemeProviderComponent>
        <Carousel />
      </ThemeProviderComponent> */}
      {/* <Reviews /> */}
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
