import HeroImage from '../Hero/Hero';
import NavBar from '../NavBar/NavBar';


export const Header = () => (
    <div>
        <NavBar />
        <HeroImage />
    </div>
);
