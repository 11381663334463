import styled from 'styled-components';

export const ContactSection = styled.section`
    background-color: #f9f9f9;
    padding: 0;
    color: black;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin: 1rem 0; // Removed side margins to make better use of width on small screens
    word-spacing: 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 3px solid transparent;
    
    @media (max-width: 1000px) {
        padding: 1.5rem 0.5rem; // Adjusted padding for tablets and mobiles
    }
`;

export const ContactTitle = styled.h2`
    font-size: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 1000px) {
        font-size: 1.5rem; // Reduced font size for smaller screens
    }
`;

export const ContactDescription = styled.p`
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;

    @media (max-width: 1000px) {
        font-size: 1rem; // Reduced font size for smaller screens
    }
`;

export const ContactButton = styled.button`
    background-color: black;
    font-size: 1.5rem;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1.5rem;

    a {
        color: white;
        text-decoration: none;
    }

    &:hover {
        background-color: grey;
        color: white;
    }

    @media (max-width: 1000px) {
        font-size: 1.2rem; // Reduced font size for smaller screens
        padding: 0.75rem 1.5rem; // Adjusted padding for a balanced look on mobiles
    }
`;
