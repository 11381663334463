import * as Styled from './Qualities.styled';

const Qualities = () => {
    const qualities = ["CRAFTSMANSHIP", "PRECISION", "INNOVATION", "SUSTAINABILITY", "BESPOKE DESIGN"];

    return (
        <Styled.QualitiesContainer id="about">
            {qualities.map((quality, index) => (
                <Styled.Quality key={index}>{quality}</Styled.Quality>
            ))}
        </Styled.QualitiesContainer>
    );
};

export default Qualities;