import styled from 'styled-components';

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;  
    margin: 6rem auto 2rem auto;

    @media (max-width: 1000px) {
        width: 80%;
    }

    @media (max-width: 600px) {
        width: 80%;
    }
`;

export const Title = styled.h1`
    font-size: 2rem;
    color: #ffffff;         // White text
    text-align: center;
    margin: 2;
    word-spacing: 3px;
    letter-spacing: 1px;    // Slight letter spacing for modern typography
    font-weight: 500;       // Medium-bold weight
    background-color: #333; // Dark background
    border-radius: 10px;    // Rounded corners
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.1),          // Subtle box shadow for depth
        0 2px 4px rgba(0, 0, 0, 0.06); 
    text-shadow: 
        1px 1px 2px rgba(0, 0, 0, 0.2);        // Subtle text shadow for better text contrast
    transition: transform 0.2s ease, box-shadow 0.2s ease; // Smooth transition for hover effect

    &:hover {
        transform: translateY(-2px);            // Slight upward movement on hover
        box-shadow: 
            0 6px 8px rgba(0, 0, 0, 0.12), 
            0 4px 6px rgba(0, 0, 0, 0.08);
    }

    @media (max-width: 1000px) {
        font-size: 2rem;
    }

    @media (max-width: 600px) {
        font-size: 1.5rem;
    }
`;

export const Input = styled.input`
    padding: 10px 0;
    margin: 10px 0;
    border: 1px solid #ccc;
    width: 100%;
    text-align: center;
    @media (max-width: 600px) {
        padding: 8px;
        width: 93%;
    }

`;

export const Textarea = styled.textarea`
    padding: 10px 0;
    margin: 10px 0;
    border: 1px solid #ccc;
    resize: vertical;
    width: 100%;
    text-align: center;

    @media (max-width: 600px) {
        padding: 8px;
        width: 93%;
    }
`;

export const Select = styled.select`
    padding: 10px 0;
    margin: 10px 0;
    border: 1px solid #ccc;
    width: 100%;
    text-align: center;

    @media (max-width: 600px) {
        padding: 8px;
        width: 98%;
    }
`;

export const Button = styled.button`
    background-color: #333;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    font-weight: bold;

    &:hover {
        background-color: green;
        transform: translateY(-2px);            // Slight upward movement on hover
        box-shadow: 
            0 6px 8px rgba(0, 0, 0, 0.12), 
            0 4px 6px rgba(0, 0, 0, 0.08);
    }

    @media (max-width: 1000px) {
        padding: 8px 12px;
    }

    @media (max-width: 600px) {
        padding: 6px 10px;
    }
`;